import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();
	const { vueApp } = nuxtApp;

	if (!config.public.sentry.dsn) {
		return;
	}

	Sentry.init({
		app: [vueApp],
		environment: process.dev ? 'development' : 'production',
		dsn: config.public.sentry.dsn,

		beforeSend(event, hint) {
			const error = hint.originalException;
			if (
				error &&
				error.message &&
				error.message.match(/Cannot destructure property 'bum' of 'p' as it is null./)
			) {
				return null;
			}

			return event;
		},
	});
});
